import { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function SearchBar({
    callback = () => {},
    delay = 600,
    length,
    count,
    placeholder = 'Search',
    backgroundColor = 'white',
    clearButton,
    sx,
}) {
    const [input, setInput] = useState(null);
    const timeout = useRef(null);

    useEffect(() => {
        if (input !== null) {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }

            timeout.current = setTimeout(() => {
                callback(input);
            }, delay);

            return () => {
                clearTimeout(timeout.current);
            };
        }
    }, [input]);

    return (
        <Box display="flex" position="relative" width="100%" sx={sx}>
            <Typography
                fontSize={18}
                width="100%"
                component="input"
                sx={{
                    borderRadius: 100,
                    backgroundColor: backgroundColor,
                    outline: 'none',
                    border: 'none',
                    width: '100%',
                    padding: 1.5,
                    paddingLeft: 3,
                    paddingRight: 3,
                }}
                placeholder={placeholder}
                value={input || ''}
                onChange={(e) => {
                    setInput(e.target.value);
                }}
            />
            <Box
                position="absolute"
                display="flex"
                top={0}
                right={10}
                height="100%"
            >
                {length !== undefined && count !== undefined && (
                    <Typography
                        color="text.secondary"
                        marginTop="auto"
                        marginBottom="auto"
                        marginRight={1}
                    >
                        {length} / {count}
                    </Typography>
                )}
                {clearButton && (
                    <IconButton
                        onClick={() => setInput('')}
                        sx={{
                            marginTop: 0.8,
                            marginBottom: 'auto',
                        }}
                        disabled={!input || input.length === 0}
                    >
                        <CloseIcon style={{}} />
                    </IconButton>
                )}
            </Box>
        </Box>
    );
}

export default SearchBar;
