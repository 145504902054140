import React from 'react';
import FlaggedComments from '../../components/administration/FlaggedComments';
import BrokerRequests from '../../components/administration/BrokerRequests';
import UserList from '../../components/administration/UserList';
import ReservedUsernames from '../../components/administration/ReservedUsernames';
import BrokerPairings from '../../components/administration/BrokerPairings';
import { Divider } from '@mui/material';

function Users() {
    return (
        <div className="admin-content">
            <UserList />
            <BrokerRequests />
            <BrokerPairings />

            <Divider sx={{ marginTop: 5, marginBottom: 5 }} />

            <FlaggedComments />
            <ReservedUsernames />
        </div>
    );
}

export default Users;
