import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import {
    SingleMediaPicker,
    buildTableColumns,
    camelToString,
    inspectArrayWithString,
} from '../../scripts/cms';
import {
    Box,
    FormControl,
    IconButton,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import ArrayInputField from './ArrayInputField';
import DragArea from './media/DragArea';
import MediaUploader from './media/MediaUploader';
import ToggleInputField from './ToggleInputField';
import { Lock, Info } from '@mui/icons-material';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import DynamicIcon from '../common/DynamicIcon';
import SelectionTable from './SelectionTable';

function Field({
    id,
    yup,
    input = {},
    editing,
    children,
    sx,
    parentAttribute,
    locked,
}) {
    const { register, watch, setValue, errors, clearErrors, control } = yup;

    let {
        label,
        placeholder,
        disabled,
        optional,
        number,
        paragraph,
        checkbox,
        step,
        dateTime,
        date,
        onChange,
        mask,
        hideLabel,
        rows,
        password,
        maxLength,
        min,
        max,
        source, // Function that updates the "result" state
        sourceBody, // Optional additional param object for source function call
        sourceParameters, // Optional array to update sourceBody based on form inputs
        sourceAttribute, // Optional string to specify the user-facing attribute shown in inputs (ex. dropdown label)
        sourceLoad, // Boolean to decide whether ther source result should just set it's value into the form
        table, // Table row selector component
        media, // Single / Multi-Media selector
        inputs, // Inputs that will be used in an array field
        restricted, // Disables field if the user is in "edit" mode on a field that shouldn't be changed after creation
        delayed, // Disables field unless post-creation (editing)
        toggle, // Switch between input sets based on the chosen tab
        Component, // Custom component renderer
        description, // Text description for the input attribute, describes how it's used for the user
        props, // Custom component props
        search, // Search input (supports [SelectionTable])
    } = input;

    // Handle hildren as variable or when used within <Field>...</Field>
    children = input.children || children;

    // Mark input as disabled if it's a restricted field that's being edited
    if (!disabled) {
        disabled = (restricted && editing) || locked;
    }

    // The error for this field's id
    const error = inspectArrayWithString(errors, id);
    const value = watch(id) || null;

    function handleChange(e) {
        // Reset errors
        if (clearErrors && error?.message) {
            clearErrors(id);
        }

        // Call custom onChange if available
        if (onChange) {
            onChange(e);
        }
    }

    const [result, setResult] = useState([]);
    const [stateSourceBody, setStateSourceBody] = useState(sourceBody);

    // Update source body whenever the parent it depends on changes input value
    // TODO: This code will absolutely cause performance issues / excessive re-renders on the frontend
    if (sourceParameters) {
        let newBody = {};
        for (let i = 0; i < sourceParameters.length; i++) {
            const param = sourceParameters[i];

            // Dependency is adjacent to the current field (part of the child object)
            if (param.child) {
                newBody[param.as || param.id] = watch(
                    `${parentAttribute}.${param.id}`
                );
            }
            // Dependency is part of the array object's parent (ex. specify parent in `upgradeOptions`, will depend on `upgrade.{paramId}`)
            else if (param.parent) {
                const split = id.split('.');
                const parentId = split
                    .slice(0, split.length - (param.split || 3))
                    .join('.');
                newBody[param.as || param.id] = watch(
                    `${parentId}.${param.id}`
                );
            }
            // Hardcoded value
            else if (param.value) {
                newBody[param.id] = param.value;
            }
            // Dependency is part of the main yup object
            else {
                newBody[param.as || param.id] = watch(param.id);
            }
        }
        // Update the body if it's changed
        if (JSON.stringify(stateSourceBody) !== JSON.stringify(newBody)) {
            setStateSourceBody(newBody);
            // Reset the dependent's value because the parent value was changed
            // (but ensure it's not on first render, because then the dropdown may have a value but no item visibly selected)
            if (stateSourceBody !== undefined) {
                setValue(id, null);
            }
        }
    }

    // Monitor updates to source body
    useEffect(() => {
        setStateSourceBody(sourceBody);
    }, [sourceBody]);

    // Load source data if expected
    useEffect(() => {
        if (source) {
            source(setResult, stateSourceBody);
        }
    }, [source, stateSourceBody]);

    // Load form with the source result if specified
    useEffect(() => {
        if (sourceLoad && result) {
            for (let key in result) {
                setValue(key, result[key]);
            }
        }
    }, [result, sourceLoad]);

    // Auto-label using variable name if one isn't provided
    if (!label) {
        const endOfId = id.split('.');
        label = camelToString(endOfId[endOfId.length - 1]);
    }

    let content = <></>;

    // Array input
    if (inputs) {
        return <ArrayInputField input={input} yup={yup} />;
    }

    // Toggle input
    else if (toggle) {
        return (
            <ToggleInputField
                input={input}
                yup={yup}
                parentAttribute={parentAttribute}
                locked={locked}
            />
        );
    }

    // Custom component
    else if (Component) {
        return <Component yup={yup} {...props} />;
    }

    // Table input
    else if (table) {
        // Exit if the input isn't required anymore
        if (delayed && !editing) {
            return null;
        }

        const data = source ? result : children;
        content = (
            <SelectionTable
                id={id}
                error={error}
                rows={data}
                columnName={table.columnName || label}
                column={table.column || 'title'}
                columns={buildTableColumns(table.columns, data)}
                control={control}
                setValue={setValue}
                multi={table.multi}
                nullable={table.nullable}
                disabled={disabled}
                search={search}
            />
        );
    }

    // Media Uploader
    else if (media) {
        if (media.limit === 1) {
            const realId = parentAttribute
                ? `${parentAttribute}.${input.id}`
                : input.id;

            content = (
                <Box display="flex">
                    <SingleMediaPicker
                        yup={yup}
                        item={watch(realId)}
                        id={realId}
                        label={label}
                        mediaTypeId={media.mediaTypeId}
                        parentAttribute={parentAttribute}
                    />
                </Box>
            );
        } else {
            content = (
                <Box className="auction-media" overflow="auto">
                    <DragArea
                        id={input.id}
                        source={watch(input.id)}
                        canDelete={!media.reference}
                        cols={6}
                    />
                    {!media.reference && (
                        <MediaUploader setValue={setValue} watch={watch} />
                    )}
                </Box>
            );
        }
    }

    // Dropdown input
    else if ((source && result) || children) {
        content = (
            <FormControl
                className={`form-control ${error ? 'is-invalid' : ''}`}
            >
                <Select
                    native
                    id={id}
                    name={id}
                    {...register(id)}
                    disabled={disabled}
                    onChangeCapture={(e) => setValue(id, e.target.value)}
                    value={value || ''}
                >
                    {placeholder ? (
                        <option component="option" value={''}>
                            {placeholder}
                        </option>
                    ) : (
                        <option component="option" hidden value={null} />
                    )}
                    {(children || result).map((item, i) => {
                        let curr = item.title || item.id;
                        if (sourceAttribute) {
                            curr = inspectArrayWithString(
                                item,
                                sourceAttribute
                            );
                        }
                        return (
                            <option
                                component="option"
                                key={i}
                                value={item.id}
                                disabled={item.disabled}
                                style={{
                                    backgroundColor: item.disabled
                                        ? '#eee'
                                        : undefined,
                                }}
                            >
                                {curr}
                            </option>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }

    // Paragraph input (rich text)
    else if (paragraph) {
        content = (
            <>
                <input type="hidden" id={id} {...register(id)} />
                <div id="hidden-toolbar" hidden />
                <trix-editor
                    input={id}
                    name={id}
                    {...register(id)}
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    placeholder={placeholder}
                    style={{
                        maxHeight: 300,
                        overflowY: 'auto',
                        cursor: disabled ? 'not-allowed' : undefined,
                    }}
                    contenteditable={!disabled}
                    toolbar={disabled ? 'hidden-toolbar' : undefined}
                />
            </>
        );
    }

    // Masked input
    else if (mask) {
        content = (
            <InputMask
                id={id}
                name={id}
                type="text"
                {...register(id)}
                mask={mask || ''}
                disabled={disabled}
                maskChar=""
                className={`form-control ${error ? 'is-invalid' : ''}`}
                placeholder={placeholder}
                onChange={handleChange}
                defaultValue={value}
            >
                {(props) => (
                    <TextField
                        className="form-control"
                        {...props}
                        disabled={disabled}
                    />
                )}
            </InputMask>
        );
    }

    // Date time
    else if (dateTime || date) {
        const Picker = dateTime ? DateTimePicker : DatePicker;
        content = (
            <Box display="flex">
                <Picker
                    id={id}
                    name={id}
                    {...register(id)}
                    control={control}
                    className={`${checkbox ? '' : 'form-control'} ${
                        error ? 'is-invalid' : ''
                    }`}
                    disabled={disabled}
                    onChange={(m) => setValue(id, m?.format())}
                    value={value}
                    renderInput={(props) => <TextField {...props} />}
                />
                {optional && (
                    <Box margin="auto">
                        <IconButton
                            onClick={() => setValue(id, null)}
                            disabled={disabled}
                        >
                            <DynamicIcon icon="Cancel" />
                        </IconButton>
                    </Box>
                )}
            </Box>
        );
    }

    // Standard input
    else {
        let type = 'text';
        if (number) type = 'number';
        else if (checkbox) type = 'checkbox';
        else if (password) type = 'password';

        content = (
            <TextField
                id={id}
                name={id}
                type={type}
                {...register(id)}
                className={`${checkbox ? '' : 'form-control'} ${
                    error ? 'is-invalid' : ''
                }`}
                placeholder={placeholder}
                disabled={disabled}
                InputProps={{
                    style: {
                        fontFamily: password ? 'inter' : 'urbanist',
                    },
                }}
                onChangeCapture={handleChange}
                inputProps={{
                    maxLength: maxLength,
                    step: step ? step : 1,
                    min: number ? min : null,
                    max: number ? max : null,
                }}
                multiline={rows > 0}
                rows={rows}
                onWheel={(e) => e.target.blur()}
            />
        );
    }

    return (
        <Box className="input-panel-item" sx={sx}>
            {!hideLabel && (
                <div className="input-label">
                    {description && (
                        <Tooltip
                            title={
                                <Typography fontSize={14} padding={1}>
                                    {description}
                                </Typography>
                            }
                        >
                            <Info
                                sx={{
                                    marginRight: 0.5,
                                    marginBottom: 0.25,
                                    color: 'status.info',
                                }}
                                fontSize="inherit"
                            />
                        </Tooltip>
                    )}
                    {disabled && (
                        <Lock
                            sx={{ marginRight: 0.5, marginBottom: 0.25 }}
                            fontSize="inherit"
                        />
                    )}
                    {`${label} `}
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                        {optional ? '' : '*'}
                    </span>
                </div>
            )}
            {content}
            <Typography className="text-danger" fontSize={14}>
                {error?.message}
            </Typography>
        </Box>
    );
}

export default Field;
