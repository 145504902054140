import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import CollapseContainer from '../common/CollapseContainer';
import { DataGrid } from '@mui/x-data-grid';
import { Tooltip, IconButton, Box, Button, Typography } from '@mui/material';
import { getRow } from '../../scripts/table';
import PopupForm from '../common/PopupForm';
import { getBrokers, useYup } from '../../scripts/cms';
import YesIcon from '@mui/icons-material/CheckCircle';
import NoIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import NoData from '../common/NoData';
import { toast } from 'react-toastify';

function BrokerPairings() {
    const [brokers, setBrokers] = useState([]);
    const [users, setUsers] = useState([]);

    const [broker, setBroker] = useState();
    const [row, setRow] = useState();

    const [showPairing, setShowPairing] = useState(false);

    useEffect(() => {
        getUsers();
        getBrokers(setBrokers);
    }, []);

    function getUsers() {
        axios
            .get(`/user/getAdmin`)
            .then((res) => {
                setUsers(res.data);
            })
            .catch(function () {});
    }

    function updatePairing(userId, brokerId, active) {
        axios
            .post(`/broker/updatePairingAdmin`, { userId, brokerId, active })
            .then((res) => {
                setShowPairing(false);
                getUsers();
                toast.success(
                    `Successfully ${active ? 'paired' : 'unpaired'} client.`
                );
            })
            .catch(function (err) {
                setShowPairing(false);
                toast.error(err?.response?.data?.message || 'Request failed.');
            });
    }

    function getPairing(pairings) {
        return pairings.filter((pairing) => pairing.brokerId === +broker.id)[0];
    }

    function isPaired(pairings) {
        const pairing = getPairing(pairings);
        return pairing?.approved === true;
    }

    const brokerColumns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'username',
            headerName: 'Username',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
    ];

    const userColumns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'username',
            headerName: 'Username',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'paired',
            headerName: 'Paired',
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const user = users.filter((a) => a.id === params.row.id)[0];

                const onClick = (e) => {
                    setRow(getRow(e, params));
                    setShowPairing(true);
                };

                if (!user.activated) {
                    return '';
                }

                // Pairing created
                if (getPairing(user.brokerPairings)) {
                    // Successful pairing
                    if (isPaired(user.brokerPairings)) {
                        return (
                            <Tooltip title={`Paired`}>
                                <IconButton onClick={onClick}>
                                    <YesIcon
                                        style={{
                                            color: '#32bf3e',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                    // Pending pairing
                    else {
                        return (
                            <Tooltip title={`Pending`}>
                                <IconButton onClick={onClick}>
                                    <PendingIcon
                                        style={{
                                            color: '#1874FF',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                }
                // Unassociated
                else {
                    return (
                        <Tooltip title={`Not Paired`}>
                            <IconButton onClick={onClick}>
                                <NoIcon
                                    style={{
                                        color: '#ee3333',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    );
                }
            },
            sortable: false,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
    ];

    return (
        <div>
            <CollapseContainer title="Broker Pairings">
                <Box display="flex" gap={1}>
                    <Box width="100%">
                        <Typography padding={1} fontWeight="medium">
                            Broker
                        </Typography>
                        <div style={{ height: 645, width: '100%' }}>
                            <DataGrid
                                rows={brokers.map((item) => {
                                    return {
                                        id: item.id,
                                        username: item.user?.username || 'N/A',
                                        name: `${item?.firstName} ${item?.lastName}`,
                                        createdAt: moment(
                                            item.createdAt
                                        ).format(`MMM D[,] h:mm A`),
                                    };
                                })}
                                columns={brokerColumns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                selectionModel={broker?.id || []}
                                onSelectionModelChange={(ids) => {
                                    setBroker(
                                        brokers.filter(
                                            (broker) => broker.id === ids[0]
                                        )[0]
                                    );
                                }}
                            />
                        </div>
                    </Box>
                    <Box width="100%">
                        <Typography padding={1} fontWeight="medium">
                            User
                        </Typography>
                        {broker ? (
                            <div style={{ height: 645, width: '100%' }}>
                                <DataGrid
                                    rows={users.map((item) => {
                                        return {
                                            id: item.id,
                                            username: item.username,
                                            name: item.verified
                                                ? `${item?.firstName} ${item?.lastName}`
                                                : `${item.contactName} (Unverified)`,
                                            pairing: getPairing(
                                                item.brokerPairings
                                            ),
                                            paired: isPaired(
                                                item.brokerPairings
                                            ),
                                            createdAt: moment(
                                                item.createdAt
                                            ).format(`MMM D[,] h:mm A`),
                                        };
                                    })}
                                    columns={userColumns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                />
                            </div>
                        ) : (
                            <NoData description="Choose a broker on the left to manage their clients." />
                        )}
                    </Box>
                </Box>
            </CollapseContainer>

            <PopupForm
                title={row?.paired ? 'Remove Client' : 'Add Client'}
                body={
                    <Box>
                        <Typography marginBottom={1}>
                            {`Are you sure you want to ${
                                row?.paired ? 'DELETE' : 'CREATE'
                            } this broker-client association?`}
                        </Typography>

                        <Typography>
                            <b>Broker: </b>{' '}
                            {`${broker?.firstName || ''} ${
                                broker?.lastName || ''
                            }`}
                        </Typography>
                        <Typography>
                            <b>Client: </b> {row?.name}
                        </Typography>
                    </Box>
                }
                show={showPairing}
                setShow={setShowPairing}
                action={() => updatePairing(row?.id, broker?.id, !row?.paired)}
            />
        </div>
    );
}

export default BrokerPairings;
