import newRelic from './newrelic.txt';

function addScript(id, src, innerHTML, async) {
    let script = document.createElement('script');

    if (id) script.setAttribute('id', id);
    if (src) script.setAttribute('src', src);
    if (async) script.setAttribute('async', '');

    script.setAttribute('type', 'text/javascript');

    if (innerHTML) script.innerHTML = innerHTML;

    document.head.appendChild(script);
}

function addMeta(name, content) {
    let meta = document.createElement('meta');

    meta.setAttribute('name', name);
    meta.setAttribute('content', content);

    document.head.appendChild(meta);
}

function loadScripts() {
    // Zumrails
    addScript(
        'zumrailssdk',
        process.env.REACT_APP_ENV === 'production'
            ? 'https://cdn.zumrails.com/production/zumsdk.js'
            : 'https://cdn.zumrails.com/production/zumsdk.js',
        null,
        true
    );

    // Production only
    if (process.env.REACT_APP_ENV === 'production') {
        // Clarity
        addScript(
            null,
            null,
            `(function (c, l, a, r, i, t, y) {
                    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                    t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
                    y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
                })(window, document, "clarity", "script", "k2bsid5ktm");`
        );

        // Segment
        addScript(
            null,
            null,
            `!function () {
                    var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
                        analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"]; analytics.factory = function (e) { return function () { var t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics } }; for (var e = 0; e < analytics.methods.length; e++) { var key = analytics.methods[e]; analytics[key] = analytics.factory(key) } analytics.load = function (key, e) { var t = document.createElement("script"); t.type = "text/javascript"; t.async = !0; t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js"; var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(t, n); analytics._loadOptions = e }; analytics._writeKey = "EfB6pgONKMpTNgwYFsGcA6by3CpHaqL1";; analytics.SNIPPET_VERSION = "4.15.3";
                        analytics.load("EfB6pgONKMpTNgwYFsGcA6by3CpHaqL1");
                        analytics.page();
                    }
                }();`
        );

        // Zen Desk
        addScript(
            'ze-snippet',
            'https://static.zdassets.com/ekr/snippet.js?key=ba640885-662f-4a10-8af7-d5a90f209dd4'
        );

        // New Relic
        fetch(`${process.env.REACT_APP_CLIENT}${newRelic}`).then((file) => {
            file.text().then((text) => {
                addScript(null, null, text);
            });
        });
    } else {
        // Hide from Google search index outside of production
        addMeta('robots', 'noindex');
    }
}

export { loadScripts };
