import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NotFound from './NotFound';
import { Box, Grid } from '@mui/material';
import Footer from '../components/common/Footer';
import Loading from '../components/common/Loading';
import { socket } from '../scripts/socket';

import ImageGallery from '../components/auction/ImageGallery';

import 'react-autocomplete-input/dist/bundle.css';
import './Auction.css';
import AddressDetails from '../components/auction/AddressDetails';
import FullscreenMedia from '../components/modal/FullscreenMedia';
import AuctionPanel from '../components/auction/AuctionPanel';
import StatusModal from '../components/modal/StatusModal';

// import Confetti from 'react-confetti';

import { styled } from '@mui/material/styles';
import AuctionDetails from '../components/auction/AuctionDetails';
import { setTitle } from '../scripts/style';
import PaddedBox from '../components/common/PaddedBox';
import RelatedAuctions from '../components/auction/RelatedAuctions';

function Auction() {
    const { slug } = useParams();
    const [auction, setAuction] = useState({});
    const [failed, setFailed] = useState(false);

    const [fullscreenMedia, setFullscreenMedia] = useState(null);

    const [statusModalShow, setStatusModalShow] = useState(false);
    const [statusModalValues, setStatusModalValues] = useState();

    const [confetti, setConfetti] = useState([]);

    useEffect(() => {
        // Get the auction data
        axios
            .get(`/auction/get/${slug}`)
            .then((res) => {
                setAuction(res.data);
                setTitle(res.data.title);
            })
            .catch(function () {
                setFailed(true);
            });
    }, [slug]);

    // Receive Auction Update
    useEffect(() => {
        if (auction.id) {
            // Auction - Extend
            socket.off(`auction/extend`).on(`auction/extend`, (info) => {
                if (auction.id === info.auctionId) {
                    setAuction({
                        ...auction,
                        endDate: info.endDate,
                    });
                }
            });

            // Auction - Disable
            socket.off(`auction/disable`).on(`auction/disable`, (info) => {
                if (auction.id === info.auctionId) {
                    setAuction({
                        ...auction,
                        disabled: true,
                    });
                }
            });

            // Auction - Sold
            socket.off(`auction/sold`).on(`auction/sold`, (info) => {
                if (auction.id === info.auctionId) {
                    setAuction({
                        ...auction,
                        finished: true,
                        sold: info.sold,
                        endDate: new Date().getTime(),
                    });
                }
            });

            // Auction - Won
            socket.off(`auction/won`).on(`auction/won`, (info) => {
                if (auction.id === info.auctionId) {
                    setAuction({
                        ...auction,
                        winner: true,
                    });
                }
            });

            // Bid - Count
            socket.off(`bid/count`).on(`bid/count`, (info) => {
                if (auction.id === info.auctionId) {
                    setAuction({
                        ...auction,
                        bidCount: info.bidCount,
                    });
                }
            });

            // Comment - Create
            socket.off(`comment/create`).on(`comment/create`, (info) => {
                if (auction.id === info.auctionId) {
                    if (
                        auction.comments.filter(
                            (comment) => comment.id === info.id
                        ).length === 0
                    ) {
                        let _comments = [...auction.comments];
                        _comments.push(info);
                        _comments.sort(
                            (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                        );
                        setAuction({
                            ...auction,
                            comments: _comments,
                        });
                    }
                }
            });

            // Comment - Delete
            socket.off(`comment/delete`).on(`comment/delete`, (info) => {
                if (auction.id === info.auctionId) {
                    // Update auction details
                    setAuction({
                        ...auction,
                        comments: auction.comments.filter(
                            (comment) => comment.id !== info.id
                        ),
                    });
                }
            });

            // Bid - Create
            socket.off(`bid/create`).on(`bid/create`, (info) => {
                if (auction.id === info.auctionId) {
                    if (
                        auction.bids.filter((bid) => bid.id === info.id)
                            .length === 0
                    ) {
                        let _bids = [...auction.bids];
                        _bids.push(info);
                        _bids.sort(
                            (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                        );
                        setAuction({
                            ...auction,
                            bids: _bids,
                            minimumBid: info.minimumBid,
                        });
                    }
                }
            });

            // Bid - Delete
            socket.off(`bid/delete`).on(`bid/delete`, (info) => {
                if (auction.id === info.auctionId) {
                    // Remove the deleted bid(s)
                    let _bids = auction.bids;
                    for (let i = 0; i < info.ids.length; i++) {
                        _bids = _bids.filter((bid) => bid.id !== info.ids[i]);
                    }

                    // Update auction details
                    setAuction({
                        ...auction,
                        bids: _bids,
                        minimumBid: info.minimumBid,
                    });
                }
            });

            // System Message - Create
            socket
                .off(`system/auction/create`)
                .on(`system/auction/create`, (info) => {
                    if (auction.id === info.auctionId) {
                        if (
                            auction.auctionSystemMessages.filter(
                                (message) => message.id === info.id
                            ).length === 0
                        ) {
                            let _messages = [...auction.auctionSystemMessages];
                            _messages.push(info);
                            _messages.sort(
                                (a, b) =>
                                    new Date(b.createdAt) -
                                    new Date(a.createdAt)
                            );
                            setAuction({
                                ...auction,
                                auctionSystemMessages: _messages,
                            });
                        }
                    }
                });

            // System Message - Delete
            socket
                .off(`system/auction/delete`)
                .on(`system/auction/delete`, (info) => {
                    if (auction.id === info.auctionId) {
                        // Update auction details
                        setAuction({
                            ...auction,
                            auctionSystemMessages:
                                auction.auctionSystemMessages.filter(
                                    (message) => message.id !== info.id
                                ),
                        });
                    }
                });
        }
    }, [auction]);

    const createStatusModal = (status, title, body, link, button) => {
        setStatusModalValues({ status, title, body, link, button });
        setStatusModalShow(true);
    };

    const createConfetti = () => {
        const item = { run: true, duration: 8000, pieces: 350 };
        setConfetti([...confetti, item]);
    };

    if (failed) {
        return <NotFound />;
    } else if (auction.id != null) {
        return (
            <Box backgroundColor="white">
                <Grid container>
                    <ImagePanel>
                        <ImageGallery
                            auction={auction}
                            setFullscreenMedia={setFullscreenMedia}
                        />
                    </ImagePanel>
                    <Grid
                        item
                        flex={1}
                        sx={{ width: { xs: '100%', md: 600, lg: 625 } }}
                    >
                        <AuctionPanel
                            auction={auction}
                            setAuction={setAuction}
                            createStatusModal={createStatusModal}
                            createConfetti={createConfetti}
                        />
                    </Grid>
                </Grid>
                <PaddedBox>
                    <AuctionDetails
                        auction={auction}
                        setFullscreenMedia={setFullscreenMedia}
                    />
                    <Box
                        sx={{
                            paddingTop: 12,
                            paddingBottom: 8,
                        }}
                    >
                        <AddressDetails
                            address={auction.property.address}
                            setMedia={setFullscreenMedia}
                            popout
                            defaultTabIndex={1}
                        />
                    </Box>
                </PaddedBox>
                {/* <PaddedBox>
                    <RelatedAuctions exclude={[auction.id]} />
                </PaddedBox> */}
                <Footer />
                <FullscreenMedia
                    media={fullscreenMedia}
                    setMedia={setFullscreenMedia}
                />
                <StatusModal
                    show={statusModalShow}
                    onClose={() => setStatusModalShow(false)}
                    values={statusModalValues}
                />
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height={document.body.scrollHeight}
                    overflow="hidden"
                    style={{ pointerEvents: 'none' }}
                >
                    {/* {confetti.map((item, i) => (
                        <Confetti
                            key={i}
                            style={{ zIndex: 1301 }}
                            height={document.body.scrollHeight}
                            width={document.body.scrollWidth}
                            numberOfPieces={item.pieces}
                            tweenDuration={item.duration}
                            recycle={false}
                            run={item.run}
                        />
                    ))} */}
                </Box>
            </Box>
        );
    }
    // Loading Data
    else {
        return <Loading />;
    }
}

const ImagePanel = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${600}px)`,
    },
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${625}px)`,
    },
}));

export default Auction;
