import { Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import SearchBar from '../common/SearchBar';
import ArraySearchBar from '../common/ArraySearchBar';

const SelectionTable = ({
    error,
    rows: _rows,
    columns,
    column,
    columnName,
    control,
    setValue,
    id,
    multi,
    nullable,
    disabled,
    search,
}) => {
    const model = useWatch({ control, name: id });
    const [rows, setRows] = useState();

    const tableRef = useRef(null);

    useEffect(() => {
        // Only show selected items if the table is disabled
        if (disabled && (model > 0 || model?.length > 0)) {
            if (multi) {
                setRows(_rows.filter((row) => model?.includes(row.id)));
            } else {
                setRows(_rows.filter((row) => row.id === model));
            }
        }
        // Update rows state object if it changes
        else {
            setRows(_rows);
        }
    }, [_rows]);

    useEffect(() => {
        if (error) {
            tableRef?.current?.scrollIntoView();
        }
    }, [error]);

    const MAX = 5;
    const AUTO_HEIGHT = _rows?.length <= MAX;

    return (
        <div ref={tableRef}>
            {!disabled && (
                <ArraySearchBar
                    items={_rows}
                    setItems={setRows}
                    config={search}
                    columns={columns || [{ field: column }]}
                    length={rows?.length}
                    count={_rows?.length}
                />
            )}
            <Box height={AUTO_HEIGHT ? undefined : 370} width="100%" flex={1}>
                <DataGrid
                    autoHeight={AUTO_HEIGHT}
                    height={200}
                    rows={rows || []}
                    columns={
                        columns || [
                            {
                                field: column,
                                headerName: columnName,
                                flex: 1,
                                headerAlign: 'center',
                                align: 'center',
                            },
                        ]
                    }
                    pageSize={MAX}
                    rowsPerPageOptions={[MAX]}
                    checkboxSelection={multi}
                    selectionModel={model || []}
                    onSelectionModelChange={(ids) => {
                        if (!disabled) {
                            if (multi) {
                                setValue(id, ids);
                            } else {
                                setValue(id, ids[0]);
                            }
                        }
                    }}
                    keepNonExistentRowsSelected
                    disableSelectionOnClick={disabled}
                    sx={{ cursor: disabled ? 'not-allowed' : undefined }}
                />
            </Box>
            {nullable && !disabled && (
                <Button
                    size="tiny"
                    fullWidth
                    variant="dark"
                    sx={{ marginTop: 2 }}
                    onClick={() => setValue(id, null)}
                >
                    Clear
                </Button>
            )}
        </div>
    );
};

export default SelectionTable;
