import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import NoData from '../common/NoData';
import moment from 'moment';
import { getPriceString } from '../../scripts/language';
import { useEffect, useState } from 'react';

function DepositSchedule({
    depositSchedule,
    firmDate,
    error = 'There is no deposit schedule for this listing.',
}) {
    const [includeMethod, setIncludeMethod] = useState(false);

    useEffect(() => {
        // Determine whether payment method column should be shown
        for (let i in depositSchedule?.deposits) {
            if (depositSchedule.deposits[i]?.depositPayment?.paymentTypeId) {
                setIncludeMethod(true);
                break;
            }
        }
    }, [depositSchedule]);

    if (!depositSchedule || depositSchedule.deposits.length === 0) {
        return <NoData description={error} paddingTop={5} paddingBottom={5} />;
    }

    return (
        <Box marginBottom={2}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Due Date</TableCell>
                            {includeMethod && <TableCell>Method</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {depositSchedule.deposits.map((deposit) => {
                            const type = deposit.depositPayment?.paymentTypeId;

                            return (
                                <TableRow
                                    key={deposit.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>{deposit.number}</TableCell>
                                    <TableCell>
                                        {deposit.depositPayment?.amount
                                            ? getPriceString(
                                                  deposit.depositPayment?.amount
                                              )
                                            : deposit.percent
                                            ? `${deposit.percent}%`
                                            : getPriceString(
                                                  deposit.fixedAmount
                                              )}
                                    </TableCell>
                                    <TableCell>
                                        <DueDate
                                            deposit={deposit}
                                            firmDate={firmDate}
                                        />
                                    </TableCell>
                                    {includeMethod && (
                                        <TableCell>
                                            {deposit.depositPayment
                                                ?.manualPayment
                                                ?.manualPaymentOption?.title ||
                                                type}
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

function DueDate({ deposit, firmDate }) {
    // Exact due date
    if (deposit.depositPayment?.dueDate) {
        const dueDate = deposit.scheduleFromFirm
            ? firmDate || deposit.depositPayment.dueDate
            : deposit.depositPayment.dueDate;
        return moment(dueDate).format('MMMM DD, YYYY');
    }
    // Due days
    else {
        return deposit.dueString;
    }
}

export default DepositSchedule;
