import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import upsideLogo from '../../assets/UpsideLogo_Black.svg';
import ArrowRight from '@mui/icons-material/East';
import ArrowLeft from '@mui/icons-material/West';
import HTMLParse from './HTMLParse';
import PopupForm from './PopupForm';

function StepByStepForm({
    yup,
    pages = [],
    handleSubmit = () => {},
    errors,
    setErrors,
    loading,
}) {
    const [page, setPage] = useState(0);
    //useEffect(() => {}, []);

    async function next(errors) {
        const curr = pages[page];

        // Exit if there are any errors for the current page
        for (var error in errors) {
            if (curr.validate?.includes(error)) {
                return;
            }
        }

        const newPage = page + 1;

        if (newPage >= pages.length) {
            handleSubmit();
        } else {
            window.scrollTo({ top: 0, behavior: 'instant' });
            setPage(newPage);
        }

        if (curr.callback) {
            curr.callback();
        }
    }

    function prev() {
        if (page > 0) {
            window.scrollTo({ top: 0, behavior: 'instant' });
            setPage(page - 1);
        }
    }

    return (
        <Box
            maxWidth={{ xs: '100%', md: 800 }}
            display="flex"
            margin="auto"
            padding={{ xs: 0, md: 6 }}
            backgroundColor="tint"
            minHeight="calc(100vh - 70px)"
        >
            <Box
                marginLeft="auto"
                marginRight="auto"
                marginBottom="auto"
                backgroundColor="white"
                padding={{ xs: 3, md: 10 }}
                paddingBottom={{ xs: 2, md: 4 }}
                position="relative"
                width="100%"
                borderRadius={4}
            >
                <Box display="flex" marginBottom={8}>
                    <img
                        style={{
                            width: 200,
                            margin: 'auto',
                        }}
                        src={upsideLogo}
                        alt="Upside"
                    />
                </Box>
                {page > 0 && (
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 13,
                            left: 0,
                            margin: { xs: 3, md: 10 },
                            marginLeft: { xs: 2, md: 10 },
                        }}
                        onClick={prev}
                    >
                        <ArrowLeft sx={{ color: 'dark' }} />
                    </IconButton>
                )}
                {pages.length > 0 && (
                    <Page
                        page={pages[page]}
                        next={yup.handleSubmit(
                            () => next(),
                            (errors) => next(errors)
                        )}
                        prev={prev}
                        i={page}
                        maxI={pages.length - 1}
                        errors={errors}
                        setErrors={setErrors}
                        loading={loading}
                    />
                )}
            </Box>
        </Box>
    );
}

function Page({ page, next, i, maxI, errors, setErrors, loading }) {
    const [showConfirmation, setShowConfirmation] = useState(false);

    function handleNext() {
        if (page.confirmation) {
            setShowConfirmation(true);
        } else {
            next();
        }
    }

    const final = i === maxI;
    return (
        <Box>
            <Box>{page.body}</Box>
            <Button
                variant="white"
                size="small"
                type="border"
                sx={{
                    paddingLeft: 5,
                    paddingRight: 5,
                    marginTop: 5,
                }}
                onClick={handleNext}
            >
                {page.button || (final ? 'Submit' : 'Continue')}
                <ArrowRight style={{ marginLeft: 10, fontSize: 20 }} />
            </Button>
            <Typography width="100%" marginTop={4} textAlign="center">
                {i + 1} / {maxI + 1}
            </Typography>
            {page.confirmation && (
                <PopupForm
                    title={page.confirmation.title}
                    body={page.confirmation.body}
                    action={next}
                    show={showConfirmation}
                    setShow={setShowConfirmation}
                    errors={errors}
                    setErrors={setErrors}
                    processing={loading}
                />
            )}
        </Box>
    );
}

export function MultipleChoice({
    title,
    body,
    content,
    options,
    attribute,
    yup,
    defaultValue,
    sx,
}) {
    const value = yup.watch(attribute);

    useEffect(() => {
        // Set the default form value if it hasn't been defined yet
        if (value === undefined) {
            yup.setValue(
                attribute,
                (defaultValue || options[0].value).toString()
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Box marginTop={2} sx={sx}>
            <Box>
                <Typography fontWeight="bold" gutterBottom>
                    {title}
                </Typography>
                <Typography component="div">
                    <HTMLParse value={body} />
                </Typography>
                {content}
            </Box>
            <RadioGroup
                onChange={(e) => {
                    yup.setValue(attribute, e.target.value);
                }}
                value={value || defaultValue || options[0].value}
            >
                {options.map((option) => (
                    <Box>
                        <Box display="flex">
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                                disabled={option.disabled}
                                sx={{ marginRight: 1 }}
                            />
                            <Typography
                                marginTop="auto"
                                marginBottom="auto"
                                fontSize={14}
                            >
                                {option.labelNote}
                            </Typography>
                        </Box>
                        <Typography
                            fontSize={14}
                            marginLeft={4}
                            component="div"
                            marginBottom={option.description ? 1 : 0}
                        >
                            <HTMLParse value={option.description} />
                        </Typography>
                    </Box>
                ))}
            </RadioGroup>
        </Box>
    );
}

export default StepByStepForm;
